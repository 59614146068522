<template>
	<div class="bg-shop-summary">
		<div class="tipBox">
			<p>财务数据</p>
			<ul>
        <el-tooltip placement="top" content="按今日已支付订单分销价（含企业运费）进行统计。">
          <li>{{ dataInfo.financeTotalMoney }}<span>营收总额（元）</span></li>
        </el-tooltip>
        <el-tooltip content="今日支付订单分销价总和（含企业运费）-会员价总和。" placement="top">
          <li>{{ dataInfo.financeProfit }}<span>利润总额（元）</span></li>
        </el-tooltip>
				<el-tooltip content="今日用户微信支付总金额。" placement="top">
          <li>{{ dataInfo.financeCash }}<span>代收现金总额（元）</span></li>
        </el-tooltip>
        <el-tooltip content="今日企业预付款账户中支付的总金额。" placement="top">
          <li>{{ dataInfo.financeOutMoney }}<span>支出总额（元）</span></li>
        </el-tooltip>
			</ul>
		</div>
		<div class="tipBox">
			<p>订单数据</p>
			<ul>
        <el-tooltip placement="top" content="用户已下单的订单总数">
          <li>{{ dataInfo.orderTotalNum }}<span>订单总数（笔）</span></li>
        </el-tooltip>
        <el-tooltip placement="top" content="今日下单的订单数，按下单时间统计。">
          <li>{{ dataInfo.orderNewNum }}<span>新增订单数（笔）</span></li>
        </el-tooltip>
        <el-tooltip placement="top" content="今日所有订单中待发货数（含挂起订单）。">
          <li>{{ dataInfo.orderWaitNum }}<span>待发货（笔）</span></li>
        </el-tooltip>
        <el-tooltip placement="top" content="所有订单中已完成数。">
          <li>{{ dataInfo.orderCompleteNum }}<span>已完成（笔）</span></li>
        </el-tooltip>
			</ul>
		</div>
		<div class="tipBox">
			<p>用户</p>
			<ul>
				<li>{{ dataInfo.memberTotalNum }}<span>用户总数</span></li>
				<li>{{ dataInfo.memberTodayNum }}<span>今日新增用户</span></li>
				<li>{{ dataInfo.memberYesterdayNum }}<span>昨日新增用户</span></li>
				<li>{{ dataInfo.memberPayNum }}<span>昨日付款用户</span></li>
			</ul>
		</div>
	</div>
</template>

<script>
	import * as API_buyAnyalysis from '@/api/buyAnyalysis'
	import { CategoryPicker } from '@/components'
	export default {
		name: 'buyAnalysis',
		components: {
			[CategoryPicker.name]: CategoryPicker
		},
		data() {
			return {
				dataInfo: {}
			}
		},
		created() {
			this.GET_DataInfo()
		},
		mounted() {

		},
		methods: {
			GET_DataInfo(){
				API_buyAnyalysis.enterpriseTodayData().then(res => {
					this.dataInfo = res
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tipBox{background: #fff;margin-bottom: 10px;padding: 20px;}
	.tipBox p{margin: 0;line-height: 18px;margin-bottom: 15px;font-size: 14px;}

	.conditions{display: inline-block;margin-right: 20px;}
	.conditions.right{float: right;}
	.conditions >>> .el-input{display: inline-block;}
	.conditions span{font-size: 14px;color: #606266;}

	.btnTab{margin-top: 20px;margin-bottom: -30px;position: relative;z-index: 1;width: 500px;}

	.tipBox ul{border:1px solid #eee;overflow: hidden;list-style: none;padding: 0;margin: 0;margin-top: 20px;}
	.tipBox ul li{width: 25%;float: left;box-sizing: border-box;border-right:1px solid #eee;text-align: center;padding:20px 0;color: #333;font-size: 20px;line-height: 30px;}
	.tipBox ul li span{display: block;color: #999;font-size: 14px;}
	.tipBox ul li:last-child{border-right: 0;}

	.tipBox p{line-height: 24px;padding: 0;position: relative;padding-left: 18px;font-size: 24px;color: #393c41;margin-bottom: 30px;}
	.tipBox p:before{content: ' ';display: block;width: 8px;height: 24px;background: #1a43a9;position: absolute;left: 0;top: 0;}

	.tipBox{background: none;}
	.tipBox ul{border:0;}
	.tipBox ul li{height: 228px;background: #fff;border-radius: 10px;width: 23%;margin-right: 2%;border:0;font-size: 48px;color: #393c41;line-height: 70px;padding-top: 58px;font-weight: 700;}
	.tipBox ul li span{font-size: 24px;color: #393c41;line-height: 30px;font-weight: normal;}
</style>

